import Home from "./Home";
import TermsAndConditions from "./TermsAndConditions";
import PrivacyPolicy from "./PrivacyPolicy";

const routes = [
  { path: '/', component: <Home />},
  { path: '/terms-and-conditions', component: <TermsAndConditions />},
  { path: '/privacy-policy', component: <PrivacyPolicy />},
]

export default routes;
