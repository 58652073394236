import { initializeApp } from 'firebase/app';
import { getFirestore, collection } from 'firebase/firestore/lite';

const firebaseConfig = {
    apiKey: "AIzaSyCQOE_b_gnqbj_uKTBQe2OLlIthUpukdHQ",
    authDomain: "alpaca-elite-club.firebaseapp.com",
    projectId: "alpaca-elite-club",
    storageBucket: "alpaca-elite-club.appspot.com",
    messagingSenderId: "1091161599945",
    appId: "1:1091161599945:web:625b87e9cc00cd8fa20218",
    measurementId: "G-88NYPVEP6D"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const contactFormCollection = collection(db, "contact-form");

export { db, contactFormCollection };