import React from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import alpacaEliteClubSlider from '../assets/fake-data/alpaca-data-slider';
import teamData from '../assets/fake-data/data-team';
import roadmapData from '../assets/fake-data/data-roadmap';
import faqs from '../assets/fake-data/faq';
import Roadmap from '../components/layouts/Roadmap';
import FAQ from "./FAQ";
import SliderStyle4 from "../components/slider/SliderStyle4";
import Story from "./Story";
import TeamMembers from "../components/layouts/home-6/TopSeller";
import Contact from "./Contact";

const Home = () => {
    return (
        <>
            <SliderStyle4 data={alpacaEliteClubSlider}/>
            <Story />
            <Roadmap data={roadmapData} />
            <TeamMembers members={teamData} />
            <FAQ data={faqs} />
        </>
    );
}

export default Home;
