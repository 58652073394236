import React from 'react';
import PropTypes from 'prop-types'
import shape1 from "../../assets/images/backgroup-secsion/bg-gradient1.png";
import shape2 from "../../assets/images/backgroup-secsion/bg-gradient2.png";
import shape3 from "../../assets/images/backgroup-secsion/bg-gradient3.png";
import firstImage from '../../assets/images/alpaca/roadmap-1.png';
import secondImage from '../../assets/images/alpaca/roadmap-2.png';
import thirdImage from '../../assets/images/alpaca/roadmap-3.png';

const Roadmap = props => {
    const data = props.data;

    return (
        <section className="tf-section roadmap-section live-auctions">
            <div id="roadmap" name="roadmap" className="roadmap-anchor" />
            <div className="themesflat-container position-relative">
                <img className="bgr-gradient gradient1" src={shape1} alt="Alpaca Elite Club" />
                <img className="bgr-gradient gradient2" src={shape2} alt="Alpaca Elite Club" />
                <img className="bgr-gradient gradient3" src={shape3} alt="Alpaca Elite Club" />
                <div className="row w-100 m-0">
                    <div className="col-md-12">
                        <div className="col-md-12">
                            <div className="card-title flat-slider ct mb-20 pb-40">
                                <h3 className="heading mb-style tf-title-heading pb-40 ct">
                                    <div className="tf-text roadmap-title mb-20 mt-20 pb-20">
                                        Roadmap
                                    </div>
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 item-list-container position-relative">
                        <span className="dot dot-1 done"/>
                        <span className="line line-1 done"/>
                        <span className="dot dot-2"/>
                        <span className="line line-2"/>
                        <span className="dot dot-3"/>
                        <span className="line line-3"/>
                        <img src={secondImage} className="image image-1" alt="second-roadmap-image"/>
                        <img src={firstImage} className="image image-2" alt="first-roadmap-image"/>
                        <img src={thirdImage} className="image image-3" alt="third-roadmap-image"/>
                        {data.map((item, index) => (
                            <div key={item.title} className={`item item-${index + 1}`}>
                                <span className="item-title">{item.title}</span>
                                <span className="item-date">{item.date}</span>
                                <span className="item-description">{item.description}</span>
                                {(item.items.length > 0) && (
                                    <ul className="item-items-list">
                                        {item.items.map(listItem => (<li className="item-items-one">{listItem}</li>))}
                                    </ul>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

Roadmap.propTypes = {
    data: PropTypes.array.isRequired,
};


export default Roadmap;
