import React from 'react';

function TeamMembers(props) {
    return (
        <div>
            <section id="team" name="team" className="tf-section top-seller home5 s2 ">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12">
                            <h2 className="tf-title style2 mb-25 text-center">Alpaca Elite Club Team</h2>
                            <div className="flat-tabs seller-tab tablet-30">
                                <div className="mg-t-24 d-flex flex-row flex-wrap align-content-start justify-content-around align-items-start">
                                    {props.members.map(({img, name, role}) => <Member key={name} img={img} name={name} role={role} />)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
function Member({img, name, role}) {
    return (
        <div className="sc-author-box style-2">
            <div className="author-avatar">
                <img src={img} alt="Axies" className="avatar" />
            </div>
            <div className="author-infor">
                <h5>{name}</h5>
                <span className="price">{role}</span>
            </div>
        </div>
    );
}

export default TeamMembers;
