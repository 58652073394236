import React from 'react';

import eva from '../assets/images/alpaca/eva.png'

const Story = () => {
    return (
            <section id="story" name="story">
                <div className="tf-section-story post-details">
                    <div className="themesflat-container">
                        <div className="row story-header">
                            <div className="col-md-12 pt-60">
                            </div>
                        </div>
                        <div className="wrap-flex-box style">
                            <div className="side-bar details">
                                <div className="widget widget-recent-post">
                                    <div className="image">
                                        <img src={eva} alt="Alpaca Elite Club"/>
                                    </div>
                                </div>
                            </div>
                            <div className="post">
                                <div className="inner-content">
                                    <div className="inner-post mg-t-40">
                                        <h3 className="heading mg-bt-16">Where the story began</h3>
                                        <p className="mg-bt-24">
                                            Hey, this is Eva, your story teller.
                                            Welcome to our journey to E4, a hidden planet in the heart of metaverse.

                                            At the edge of the metaverse, our people, the Alpaca, live on the planet of Alpacaan.
                                            Regrettably, as 7 diverse tribes,
                                            we have made such a mess of our beautiful planet that Alpacaan is moments from apocalypse.
                                            With little time left to save our species, we must open the meta gate, the portal of the metaverse,
                                            and start our voyage to E4.

                                            In our search to survive, we found 6 sacred tablets on our planet that together reveal a map to E4.
                                            The final tablet needed to complete the map was found on the nearby planet Calpein,
                                            land of Multidimensional Scientists. With their help,
                                            we can open the meta gate and with their starships we can travel across the metaverse.
                                        </p>
                                        <p className="mg-bt-24">
                                            Though we know where we want to go, and have a map to get there, the mysterious utopia that is E4,
                                            only shows itself to those who are ready; those who find it are wise, mature, and full of love.
                                            Therefore, the only way through this darkness and uncertainty is unity,
                                            we must all work together to successfully reach our destination.

                                            Unity in the only way - will you join us?
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
};
export default Story;
