const roadmapData = [
    {
        title: "The Awakening",
        description: "",
        date: "2023 Q3",
        items: [
            'Introducing Alpacas through social media',
            'Narrating the story! (The journey just began!)',
            'Giving away 30NFTs to the community members who help us promote our NFT by being active on our social media',
            'Donating %10 of the profit to the charities'
        ]
    },
    {
        title: "The Release",
        description: "",
        date: "2023 Q4",
        items: [
            'Giving the option of customizing the Alpacas and Choosing its Power!',
            'Creating a contest and giving away free Alpaca NFTs!',
            'Creating dynamic arts',
            'Collaboration with AEC holders to expand the project'
        ]
    },
    {
        title: "The Gift",
        description: "",
        date: "2024 Q1",
        items: [
            'Launching the E4 Club in metaverse',
            'Giving the option to AEC holders to rent out their memberships.',
            'Holding private events in metaverse (Business conferences - Parties - Gallery Shows - Puppet Shows)',
            'Making a game for AEC holders to use their powers!',
            'Forming a book that is the story of Alpacas that  our community members and founders finish it together',
            'Profit sharing based on some activities',
            'A new Journey will be revealed!'
        ]
    }
];

export default roadmapData;
