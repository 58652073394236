import React, { useState ,useEffect } from 'react';
import { Link } from 'react-router-dom'
import logodark from '../../assets/images/logo/logo_circle.png'
import logofooter from '../../assets/images/logo/logo_circle.png'

export const DISCORD_LINK = "https://discord.gg/alpacaeliteclub";
export const MEDIUM_LINK = "https://medium.com/@alpacaeliteclub";
export const INSTAGRAM_LINK = "https://instagram.com/alpacaelite.club";
export const TWITTER_LINK = "https://twitter.com/alpacaeliteclub";

const Footer = () => {

    const socialList = [
        {
            name: "twitter",
            icon: "fab fa-twitter",
            link: TWITTER_LINK
        },
        {
            name: "instagram",
            icon: "fab fa-instagram",
            link: INSTAGRAM_LINK
        },
        {
            name: "medium",
            icon: "fab fa-medium-m",
            link: MEDIUM_LINK
        },
        {
            name: "discord",
            icon: "icon-fl-vt",
            link: DISCORD_LINK
        }
    ];

    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 500) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", toggleVisibility);

        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    const ctaClick = () => {
        dataLayer.push({'event': 'cta-footer-banner' });
        console.log("Cta footer click");
    };

    function socialLink (item) {
        const clickEvent = "footer-click-" + item;
        dataLayer.push({'event': clickEvent });
        console.log("item clicked " + clickEvent);
    }

    return (
        <div>
            <footer id="footer" className="footer-light-style clearfix bg-style">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 col-12 center">
                                <div className="widget widget-subcribe">
                                    <h5 className="title-widget">Partnerships & Collabs</h5>
                                    <div className="form-subcribe">
                                        <div className="content">
                                            <p className="sub-heading mg-t-7 mg-bt-30">If you're interested in collaborating with Alpaca Elite Club</p>
                                        </div>
                                        <a target="_blank" href={DISCORD_LINK} onClick={ctaClick} className="sc-button header-slider style style-1 rocket fl-button pri-1">
                                            <span>Contact Us</span>
                                        </a>
                                    </div>
                                </div>
                        </div>
                        <div className="col-lg-4 col-md-12 mt-4 col-12">
                            <div className="content"></div>
                            <div className="widget widget-logo social-center">
                                <div className="logo-footer" id="logo-footer">
                                    <Link to="/">
                                        <img className='logo-dark' id="logo_footer" src={logodark} alt="nft-gaming" />
                                        <img className='logo-light' id="logo_footer" src={logofooter} alt="nft-gaming" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-7 col-12">
                            <div className="widget-social style-1 mg-t32 social-center">
                                <ul className="social-center">
                                    {
                                        socialList.map((item,index) =>(
                                        <li key={index}>
                                            <a target="_blank" onClick={() => socialLink(item.name)} href={item.link}><i className={item.icon}></i></a>
                                        </li>
                                        ))
                                    }
                                </ul>
                            </div>
                            <div className="widget widget-subcribe">
                                <div className="form-subcribe">
                                    <div className="content center">
                                        <p className="sub-widget-logo mb-4 mt-2">
                                            <Link to="/privacy-policy">Privacy Policy </Link>
                                            |
                                            <Link to="/terms-and-conditions"> Terms & Conditions</Link>
                                        </p>
                                        <p className="sub-heading mg-t-7 mg-bt-30">© 2022 Alpaca Elite Club | All Rights Reserved</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {
                isVisible &&
                <Link onClick={scrollToTop}  to='#' id="scroll-top"></Link>
            }
        </div>

    );
}

export default Footer;
