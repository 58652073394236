const faqs = [
    {
        question: 'What are the benefits of owning an Alpaca?',
        answer: 'Our Alpaca owners have access to the drops, giveaways, raffles, merchandise, and much more! Also, our members will have access to our exclusive elite club where private parties, social events, and festivals are being held. The main point is the elite members can make a profit by renting out the membership and getting a %1 loyalty fee from the members.'
    },
    {
        question: 'How AEC is going to stand out in the crowd?',
        answer: 'AEC NFTs are stored as ERC-721 tokens on the Ethereum Blockchain. There is a story behind the art which makes the collection more interesting. AEC is not going to stop working after this collection. It is just a start! Our Story and next projects will reveal during this journey, and the future projects will add more value to the current one. There are also some mysterious physical assets to be given to add more value.'
    },
    {
        question: 'How much is the royalty fee?',
        answer: 'We charge %7.5 royalty fees and Opensea charges %2.5 on all sales, all together %10.'
    },
    {
        question: 'Can I buy more than one Alpaca?',
        answer: 'There is no limitation on pre-sale and public-sale.'
    }
];

export default faqs;
