import React , { useRef , useState , useEffect } from 'react';
import { Link , useLocation } from "react-router-dom";
import { animateScroll as scroll } from 'react-scroll'

import menus from "../../pages/menu";
import DarkMode from './DarkMode';
import logo from '../../assets/images/logo/alpaca-elite-club-logo.png'

const Header = () => {
    const { pathname } = useLocation();
    const headerRef = useRef (null);

    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });
    const isSticky = () => {
        const header = document.querySelector('.js-header');
        const scrollTop = window.scrollY;
        scrollTop >= 300 ? header.classList.add('is-fixed') : header.classList.remove('is-fixed');
        scrollTop >= 400 ? header.classList.add('is-small') : header.classList.remove('is-small');
    };


    const menuLeft = useRef(null);
    const btnToggle = useRef(null);

    const menuToggle = () => {
        menuLeft.current.classList.toggle('active');
        btnToggle.current.classList.toggle('active');
    };

    function getName(index) {
        var returnVal = "";
        switch(index){
            case 0:
                returnVal = "story";
                break;
            case 1:
                returnVal = "roadmap";
                break;
            case 2:
                returnVal = "team";
                break;
            case 3:
                returnVal = "faq";
                break;
        }
        return returnVal;
    }

    const ctaClick = () => {
        dataLayer.push({'event': 'cta-header' });
        //console.log("Cta header click");
    };

    const [activeIndex, setActiveIndex] = useState(null);
    const handleOnClick = index => {
        const clickEvent = "menu-click-" + getName(index);
        //console.log(clickEvent);
        dataLayer.push({'event': clickEvent });
        setActiveIndex(index);
        menuToggle();
        document.getElementById(menus[index - 0].scrollTo).scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <header id="header_main" className="header_1 js-header" ref={headerRef}>
            <div className="themesflat-container">
                <div className="row">
                    <div className="col-md-12">
                        <div id="site-header-inner">
                            <div className="flex header-nav-container">
                                <div id="site-logo">
                                    <div id="site-logo-inner">
                                        <Link to="/" rel="home" className="main-logo">
                                            <img className='logo-dark' id="logo_header" src={logo} srcSet={`${logo}`} alt="Alpaca Elite Club Logo" />
                                        </Link>
                                    </div>
                                </div>
                                <nav id="main-nav" className="main-nav" ref={menuLeft} >
                                    <ul id="menu-primary-menu" className="menu">
                                        {
                                            menus.map((data,index) => (
                                                <li key={index} onClick={()=> handleOnClick(index)} className={`menu-item ${data.namesub ? 'menu-item-has-children' : '' } ${activeIndex === index ? 'active' : ''} ` }   >
                                                    <Link to={data.links}>{data.name}</Link>
                                                    {
                                                         data.namesub &&
                                                         <ul className="sub-menu" >
                                                            {
                                                                data.namesub.map((submenu) => (
                                                                    <li key={submenu.id} className={
                                                                        pathname === submenu.links
                                                                        ? "menu-item current-item"
                                                                        : "menu-item"
                                                                    }><Link to={submenu.links}>{submenu.sub}</Link></li>
                                                                ))
                                                            }
                                                        </ul>
                                                    }

                                                </li>
                                            ))
                                        }
                                    </ul>
                                </nav>
                                <div className="flat-search-btn flex">
                                    <div className="sc-btn-top mg-r-12" id="site-header">
                                        <a target="_blank" href="https://discord.gg/alpacaeliteclub" onClick={ctaClick} className="sc-button header-slider style style-1 discord fl-button pri-1">
                                            <span> Join Discord </span>
                                        </a>
                                    </div>
                                    <div className="mobile-button" ref={btnToggle} onClick={menuToggle}><span></span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DarkMode />
        </header>
    );
}

export default Header;
