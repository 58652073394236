import slide1 from '../images/slider/slide_1.jpg';
import slide2 from '../images/slider/slide_2.jpg';
import imgbg1 from '../images/slider/bg_slide_1.png'
import imgbg2 from '../images/slider/bg_slide_1.png'


const alpacaEliteClubSlider = [
    {
        title_1: "Discover, find,",
        title_2: "Sell extraordinary",
        title_3: "Monster NFTs",
        description: "Marketplace for monster character cllections non fungible token NFTs",
        img: slide1,
        imgbg: imgbg1,
        class:'left'
    },
    {
        title_1: "Discover, find,",
        title_2: "Sell extraordinary",
        title_3: "Monster NFTs",
        description: "Marketplace for monster character cllections non fungible token NFTs",
        img: slide2,
        imgbg: imgbg2,
        class:'center'
    },
    {
        title_1: "Discover, find,",
        title_2: "Sell extraordinary",
        title_3: "Monster NFTs",
        description: "Marketplace for monster character cllections non fungible token NFTs",
        img: slide2,
        imgbg: imgbg2,
        class:'right'
    },
    
]

export default alpacaEliteClubSlider;