import React, {useState} from 'react';
import {addMessage} from "../firestore-integration/contact-form/addMessage";
import {Link} from "react-router-dom";

function PrivacyPolicy() {
    return (
        <section className="tf-contact tf-section contact-us">
            <div className="themesflat-container">
                <div className="row">
                    <div className="col-12">
                        <div className="flat-form">
                            <h2 className="tf-title-heading ct style-2 mg-bt-12 mg-t-21">
                                Privacy Policy
                            </h2>
                            <div className="content mb-3 first-column ml-3 mr-3 text-content">
                                <p>
                                    <span>
                                        <b>
                                            Effective June 1st, 2022
                                        </b>
                                    </span>
                                </p>
                                <p style={{lineHeight: '100%', marginBottom: '0.5em'}}>
                                    <span style={{letterSpacing: '0.1pt'}}>
                                        <br/>
                                    </span>
                                    <br/>
                                </p>
                                <p style={{lineHeight: '100%', marginBottom: '1em'}}>
                                    <span style={{letterSpacing: '0.1pt'}}>
                                        Alpaca Elite Club is owned and operated by Alpaca Elite Club team hereby referred to as 'AEC'. By accessing AEC’s website, you are agreeing to this privacy policy and all applicable laws and regulations. Where there is an inconsistency between this privacy policy and any applicable legislation, such legislation shall prevail.
                                    </span>
                                </p>
                                <p style={{lineHeight: '100%', marginBottom: '1em'}}>
                                    <span style={{letterSpacing: '0.1pt'}}>
                                        AEC respects the privacy and confidentiality of our clients, employees, and website users. The purpose of this privacy policy is to advise our clients and website users on how AEC collects and uses, manages, and discloses personal information. Our privacy policy applies to all employees and directors of AEC, as well as, contractors, suppliers, agents, and representatives.
                                    </span>
                                </p>
                                <p style={{lineHeight: '100%', marginBottom: '1em'}}>
                                    <span style={{letterSpacing: '0.1pt'}}>
                                        AEC adheres to the Government of Canada’s Personal Information Protection and Electronic Documents Act. The Canadian Standards Association (CSA) Model Code for the Protection of Personal Information serves as the foundation for multinational exchanges of information and data protection laws. Consistent with government legislation and the CSA code, our information privacy practices are as follows:
                                    </span>
                                </p>
                                <ul>
                                    <li>
                                        <p style={{lineHeight: '100%', marginBottom: '0.5em'}}>
                                            <span style={{letterSpacing: '0.1pt'}}>
                                                Accountability: AEC is actively accountable for our clients’ personal information and ensures appropriate policies and procedures are in place to protect that information.
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p style={{lineHeight: '100%', marginBottom: '0.5em'}}>
                                            <span style={{letterSpacing: '0.1pt'}}>
                                                Identifying purposes: AEC will identify the purposes for collecting personal information.
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p style={{lineHeight: '100%', marginBottom: '0.5em'}}>
                                            <span style={{letterSpacing: '0.1pt'}}>
                                                Consent — AEC will not collect, use, or disclose personal information without the consent of the client.
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p style={{lineHeight: '100%', marginBottom: '0.5em'}}>
                                            <span style={{letterSpacing: '0.1pt'}}>
                                                Limiting collection: AEC will collect only necessary, relevant information by lawful and fair means.
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p style={{lineHeight: '100%', marginBottom: '0.5em'}}>
                                            <span style={{letterSpacing: '0.1pt'}}>
                                                Limiting use, disclosure, and retention: AEC will not use or disclose information for purposes other than for those which it was collected without consent of the individual or organization. Information will be maintained only for as long as necessary and will not be disclosed to other parties.
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p style={{lineHeight: '100%', marginBottom: '0.5em'}}>
                                            <span style={{letterSpacing: '0.1pt'}}>
                                                Accuracy: AEC will ensure any personal information collected is as accurate, complete, and up-to-date as possible for the purposes for which it will be used.
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p style={{lineHeight: '100%', marginBottom: '0.5em'}}>
                                            <span style={{letterSpacing: '0.1pt'}}>
                                                Safeguard: AEC will protect personal information using reasonable physical, administrative and procedural, and technical safeguards appropriate to its sensitivity.
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p style={{lineHeight: '100%', marginBottom: '0.5em'}}>
                                            <span style={{letterSpacing: '0.1pt'}}>
                                                Openness: AEC will make available our privacy policies and practices on request to our clients.
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p style={{lineHeight: '100%', marginBottom: '0.5em'}}>
                                            <span style={{letterSpacing: '0.1pt'}}>
                                                Individual access: clients are welcome to request access to their personal information that AEC retains to determine accuracy and completeness and have it amended as appropriate.
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p style={{lineHeight: '100%', marginBottom: '0.5em'}}>
                                            <span style={{letterSpacing: '0.1pt'}}>
                                                Challenging compliance: AEC maintains a complaint procedure and a designated person to ensure compliance with privacy legislation.
                                            </span>
                                        </p>
                                    </li>
                                </ul>
                                <p style={{lineHeight: '100%', marginBottom: '1em'}}>
                                    <span style={{letterSpacing: '0.1pt'}}>
                                        AEC takes all reasonable security measures to protect personal information from loss, unauthorized access, destruction, misuse, modification, and disclosure. However, we cannot ensure or warrant the security of any information you transmit to us over the Internet and you do so at your own risk. AEC will not be liable in any way in relation to any breach of security, or unintended loss, or disclosure of information due to our website being linked to the Internet.
                                    </span>
                                </p>
                                <p style={{lineHeight: '100%', marginBottom: '1em'}}>
                                    <h2 style={{letterSpacing: '0.1pt'}}>
                                        Privacy Questions & Answers
                                    </h2>
                                </p>
                                <p style={{lineHeight: '100%', marginBottom: '1em'}}>
                                    <h3 style={{letterSpacing: '0.1pt'}}>
                                        How does AEC collect and use my information?
                                    </h3>
                                </p>
                                <p style={{lineHeight: '100%', marginBottom: '1em'}}>
                                    <span style={{letterSpacing: '0.1pt'}}>
                                        AEC collects your personal information through various means including personal contact, job applications, phone contact, submitting your email, subscribing to newsletters, and through website cookies. This information is collected to manage our ongoing relationship with you. In general, the information collected and used about you is for the purposes of:
                                    </span>
                                </p>
                                <ul>
                                    <li>
                                        <p style={{lineHeight: '100%', marginBottom: '0.5em'}}>
                                            <span style={{letterSpacing: '0.1pt'}}>
                                                Providing information about AEC and its operations, including, but not limited to: newsletters, marketing information, software updates, and upcoming events.
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p style={{lineHeight: '100%', marginBottom: '0.5em'}}>
                                            <span style={{letterSpacing: '0.1pt'}}>
                                                Product purchases and support.
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p style={{lineHeight: '100%', marginBottom: '0.5em'}}>
                                            <span style={{letterSpacing: '0.1pt'}}>
                                                Enhancing the functionality of our website.
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p style={{lineHeight: '100%', marginBottom: '0.5em'}}>
                                            <span style={{letterSpacing: '0.1pt'}}>
                                                Managing and administering contracts.
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p style={{lineHeight: '100%', marginBottom: '0.5em'}}>
                                            <span style={{letterSpacing: '0.1pt'}}>
                                                Meeting any legal or regulatory requirement or obligation;
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p style={{lineHeight: '100%', marginBottom: '0.5em'}}>
                                            <span style={{letterSpacing: '0.1pt'}}>
                                                Developing and improving our products and services, website content, and advertising.
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p style={{lineHeight: '100%', marginBottom: '0.5em'}}>
                                            <span style={{letterSpacing: '0.1pt'}}>
                                                Sending important notices such as changes to our terms, conditions, and policies.
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p style={{lineHeight: '100%', marginBottom: '0.5em'}}>
                                            <span style={{letterSpacing: '0.1pt'}}>
                                                Managing and developing our business.
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p style={{lineHeight: '100%', marginBottom: '0.5em'}}>
                                            <span style={{letterSpacing: '0.1pt'}}>
                                                Internal auditing, data analysis, and research.
                                            </span>
                                        </p>
                                    </li>
                                    <li>
                                        <p style={{lineHeight: '100%', marginBottom: '0.5em'}}>
                                            <span style={{letterSpacing: '0.1pt'}}>
                                                Employment and contracting opportunities.
                                            </span>
                                        </p>
                                    </li>
                                </ul>
                                <p style={{lineHeight: '100%', marginBottom: '1em'}}>
                                    <h3 style={{letterSpacing: '0.1pt'}}>
                                        What information does AEC collect?
                                    </h3>
                                </p>
                                <p style={{lineHeight: '100%', marginBottom: '1em'}}>
                                    <span style={{letterSpacing: '0.1pt'}}>
                                        AEC only collects the minimum required personally identifiable data such as your name, title, company/educational institution, address, telephone and fax number, and email address, when it is voluntarily submitted by you. When you send AEC email, we collect your email address, your Internet protocol (IP) address, and your message so that we may reply to you. We recommend that sensitive personal information not be sent unencrypted over the Internet or by email.
                                    </span>
                                </p>
                                <p style={{lineHeight: '100%', marginBottom: '1em'}}>
                                    <h3 style={{letterSpacing: '0.1pt'}}>
                                        Do I have to provide my personal information?
                                    </h3>
                                </p>
                                <p style={{lineHeight: '100%', marginBottom: '1em'}}>
                                    <span style={{letterSpacing: '0.1pt'}}>
                                        No. You don’t need to provide any information that identifies you or any other personal information in order to use AEC’s website. AEC receives personal information from you only with your express consent or when you voluntarily choose to provide it. Consent may be withdrawn, subject to legal or contractual agreements. We will respond to your request within 10 business days.
                                    </span>
                                </p>
                                <p style={{lineHeight: '100%', marginBottom: '1em'}}>
                                    <h3 style={{letterSpacing: '0.1pt'}}>
                                        Does AEC release my information to others?
                                    </h3>
                                </p>
                                <p style={{lineHeight: '100%', marginBottom: '1em'}}>
                                    <span style={{letterSpacing: '0.1pt'}}>
                                        No. We do not sell, rent, or give away your personal information. We will not release your personal information to a third party, other than our auditors, affiliates, or our parent organization, AEC (all on a need-to-know basis) unless authorized by you or required by a court order. All personally identifiable information collected is subject to disclosure to judicial or other government agencies pursuant to properly obtained warrants, subpoenas, or other governmental orders.
                                    </span>
                                </p>
                                <p style={{lineHeight: '100%', marginBottom: '1em'}}>
                                    <h3 style={{letterSpacing: '0.1pt'}}>
                                        Do I have to receive information from AEC?
                                    </h3>
                                </p>
                                <p style={{lineHeight: '100%', marginBottom: '1em'}}>
                                    <span style={{letterSpacing: '0.1pt'}}>
                                        You may choose to receive information from each AEC division or you may opt out of receiving information from that division at any time simply by clicking the unsubscribe link on our correspondence. In the case of legal relationships, you may not opt out of receiving legal notices or other communications provided for in legal agreements you are a party to with AEC. This information is considered vital to your interaction with AEC. You may request to have this information deleted upon termination of your product/service agreement.
                                    </span>
                                </p>
                                <p style={{lineHeight: '100%', marginBottom: '1em'}}>
                                    <h3 style={{letterSpacing: '0.1pt'}}>
                                        How does AEC store my information and for how long?
                                    </h3>
                                </p>
                                <p style={{lineHeight: '100%', marginBottom: '1em'}}>
                                    <span style={{letterSpacing: '0.1pt'}}>
                                        AEC takes all reasonable security measures to protect your personal information. We maintain procedures to ensure your personal information is collected and stored in a secured manner. Information is only accessible to designated staff to carry out the stated purposes we have communicated to you. AEC retains information only for as long as necessary and as required by law.
                                    </span>
                                </p>
                                <p style={{lineHeight: '100%', marginBottom: '1em'}}>
                                    <h3 style={{letterSpacing: '0.1pt'}}>
                                        What information does AEC’s website collect?
                                    </h3>
                                </p>
                                <p style={{lineHeight: '100%', marginBottom: '1em'}}>
                                    <span style={{letterSpacing: '0.1pt'}}>
                                        The AEC website uses software that automatically collects the IP address of the computer through which the user is connected to the Internet. AEC’s website also automatically collects information about the users’ Internet browser software, operating system, the date and time of the users’ access, and pages viewed while on the AEC website. The referral URL may also be collected. This information is used in aggregate form for statistical purposes only to assess how our website is being used and improve the user experience.
                                    </span>
                                </p>
                                <p style={{lineHeight: '100%', marginBottom: '1em'}}>
                                    <h3 style={{letterSpacing: '0.1pt'}}>
                                        Does AEC use cookies?
                                    </h3>
                                </p>
                                <p style={{lineHeight: '100%', marginBottom: '1em'}}>
                                    <span style={{letterSpacing: '0.1pt'}}>
                                        When you view certain areas of our website information may be stored on your computer. This information will be in the form of a "cookie" or similar file and will permit tailoring of the web site to better match your interests and preferences. We do not use cookies to retrieve data from your hard drive, or to obtain your email address, or to obtain any other personal information.
                                    </span>
                                </p>
                                <p style={{lineHeight: '100%', marginBottom: '1em'}}>
                                    <span style={{letterSpacing: '0.1pt'}}>
                                        You may choose not to accept cookies by setting your browser privacy options. Please refer to your browser documentation for more information. Please note, that by not accepting cookies you may limit the functionality that we can provide when you visit our website.
                                    </span>
                                </p>
                                <p style={{lineHeight: '100%', marginBottom: '1em'}}>
                                    <span style={{letterSpacing: '0.1pt'}}>
                                        Additionally, AEC uses Google Analytics, a web analytics service provided by Google Inc., to provide website marketing data. This tool uses cookies, which are automatically downloaded on your computer to collect anonymous information. The information reported includes traffic patterns, such as how visitors access our website, pages viewed, average time on our site, and where in the world our visitors come from. If you would prefer to opt out of being tracked by Google Analytics across all websites please visit <a href="http://tools.google.com/dlpage/gaoptout" target="_blank">http://tools.google.com/dlpage/gaoptout.</a>.
                                    </span>
                                </p>
                                <p style={{lineHeight: '100%', marginBottom: '1em'}}>
                                    <h3 style={{letterSpacing: '0.1pt'}}>
                                        What about children using your website?
                                    </h3>
                                </p>
                                <p style={{lineHeight: '100%', marginBottom: '1em'}}>
                                    <span style={{letterSpacing: '0.1pt'}}>
                                        Our website is not directed at children. We do not intentionally collect information from people under the age of 18.
                                    </span>
                                </p>
                                <p style={{lineHeight: '100%', marginBottom: '1em'}}>
                                    <h3 style={{letterSpacing: '0.1pt'}}>
                                        Does AEC’s privacy policy extend to links to external websites?
                                    </h3>
                                </p>
                                <p style={{lineHeight: '100%', marginBottom: '1em'}}>
                                    <span style={{letterSpacing: '0.1pt'}}>
                                        No. Our privacy policy applies exclusively to information collected by AEC and our website. The AEC website may contain links to external websites that are provided as a convenience for our users. AEC is not responsible for the privacy practices and security of these external websites. To ensure your personal information is protected, you are responsible to review the privacy policy for each website you visit.
                                    </span>
                                </p>
                                <p style={{lineHeight: '100%', marginBottom: '1em'}}>
                                    <h3 style={{letterSpacing: '0.1pt'}}>
                                        What if I have further questions or concerns about my privacy?
                                    </h3>
                                </p>
                                <p style={{lineHeight: '100%', marginBottom: '1em'}}>
                                    <span style={{letterSpacing: '0.1pt'}}>
                                        If you have any questions or concerns regarding our privacy and website, please contact us on our
                                        <a href="https://Discord.gg/alpcaelitclub"> Discord Channel</a>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PrivacyPolicy;
