import React from "react";
import { Routes , Route } from 'react-router-dom';
import routes from './pages/routes'
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import './App.css';

function App() {
    return (
        <div className='home-7'>
            <Header />
            <Routes >
                {
                    routes.map((data,index) => (
                        <Route onUpdate={() => window.scrollTo(0, 0)} exact={true} path={data.path} element={data.component} key={index} />
                    ))
                }
            </Routes>
            <Footer />
        </div>
    );
}

export default App;
