import SatoriImage from '../images/alpaca-teams/satori.png';
import KathyImage from '../images/alpaca-teams/kathy.png';
import NellyImage from '../images/alpaca-teams/nelly.png';
import MariyaImage from '../images/alpaca-teams/mariya.png';
import NarutuImage from '../images/alpaca-teams/narutu.png';
import DavidImage from '../images/alpaca-teams/david.png';
import ElizabethImage from '../images/alpaca-teams/elizabeth.png';
import BigPapaImage from '../images/alpaca-teams/big-papa.png';

const teamData = [
    {
        img: SatoriImage,
        name: "Satori",
        role: "3D Artist"
    },
    {
        img: KathyImage,
        name: "Kathy",
        role: "Business Development",
    },
    {
        img: NellyImage,
        name: "Nelly",
        role: "Project Manager"
    },
    {
        img: MariyaImage,
        name: "Mariya",
        role: "Developer",
    },
    {
        img: NarutuImage,
        name: "Narutu",
        role: "Developer",
    },
    {
        img: DavidImage,
        name: "David",
        role: "Blockchain Advisor",
    },
    {
        img: ElizabethImage,
        name: "Elizabeth",
        role: "Voice Artist & Content Writer",
    },
    {
        img: BigPapaImage,
        name: "Big Papa",
        role: "Lead Developer",
    }
];

export default teamData;
