import React, {useState} from 'react';
import {addMessage} from "../firestore-integration/contact-form/addMessage";

const Contact = () => {
    const [message, setMessage] = useState(null);
    function submit(event) {
        event.preventDefault();
        const formData = new FormData(event.target);
        addMessage({
            email: formData.get("email"),
            fullName: formData.get("name"),
            message: formData.get("message")
        }).then(
            () => {
                setMessage({className: 'text-success', message: `Thank you ${formData.get("name")}, Message received successfully`});
                event.target.reset();
            },
            () => setMessage({className: 'text-warning', message: `Something went wrong, please give us a few minutes and try again`})
        )
    }
    return (
        <section className="tf-contact tf-section contact-us" id="contactUs">
            <div className="themesflat-container">
                <div className="row">
                    <div className="col-12">
                        <div className="flat-form">
                            <h2 className="tf-title-heading ct style-2 mg-bt-12">
                                Drop Up A Message
                            </h2>
                            <h5 className="sub-title ct style-1 pad-0-15">
                                You wanna say hi to us or let us a message like some idea or something else? fill the form, we promise to read the messages asap and contact you back.
                            </h5>
                            <div className="form-inner">
                                <form id="contactform" className="form-submit" onSubmit={submit}>
                                    <input id="name" name="name" tabIndex="1" aria-required="true" required type="text" placeholder="Your Full Name" />
                                    <input id="email" name="email" tabIndex="2"  aria-required="true" required type="email" placeholder="Your Email Address" />
                                    <textarea id="message" name="message" tabIndex="3" aria-required="true" required placeholder="Message" />
                                    <button className="submit">Send message</button>
                                    {message && <span className={message?.className}>{message?.message}</span>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;
