const menus = [
    {
        id: 1,
        name: 'Story',
        links: '/#story',
        scrollTo: 'story'
    },
    {
        id: 2,
        name: 'Roadmap',
        links: '/#roadmap',
        scrollTo: 'roadmap'
    },
    {
        id: 4,
        name: 'Team',
        links: '/#team',
        scrollTo: 'team'
    },
    {
        id: 5,
        name: 'FAQ',
        links: '/#faq',
        scrollTo: 'faq'
    }
];

export default menus;
