import React  from 'react';
import { Link } from 'react-router-dom'

import slide1 from '../../assets/images/alpaca/main-top.png'
import shape1 from '../../assets/images/backgroup-secsion/bg-gradient1.png'
import shape2 from '../../assets/images/backgroup-secsion/bg-gradient2.png'
import shape3 from '../../assets/images/backgroup-secsion/bg-gradient3.png'
import {DISCORD_LINK} from "../footer/Footer";

const SliderStyle4 = () => {

    const ctaClick = () => {
        dataLayer.push({'event': 'cta-hero-banner' });
        //console.log("Cta hero click");
    };

    return (
        <div>
            <section className="flat-title-page style3 mainslider">
                <img className="bgr-gradient gradient1" src={shape1} alt="Alpaca Elite Club" />
                <img className="bgr-gradient gradient2" src={shape2} alt="Alpaca Elite Club" />
                <img className="bgr-gradient gradient3" src={shape3} alt="Alpaca Elite Club" />
                <div className="themesflat-container">
                        <div className="wrap-heading flat-slider flex">
                            <div className="content mb-3 first-column ml-3 mr-3">
                                <h2 className="heading mt-15">Welcome to</h2>
                                <h1 className="heading mb-style"><span className="tf-text s1">Alpaca Elite Club</span></h1>
                                <h1 className="heading">The Journey Begins</h1>
                                <p className="sub-heading mt-19 mb-40">
                                    An Exclusive Club of 9900 Alpaca Elite, representing NFT Collection stored as ERC-721 tokens on the Ethereum blockchain
                                </p>
                                <div className="flat-bt-slider flex style2">
                                    <a target="_blank" href={DISCORD_LINK} onClick={ctaClick} className="sc-button header-slider style style-1 rocket fl-button pri-1 "><span>Join the club</span></a>
                                </div>
                            </div>
                            <div className="main-image-container second-column">
                                <img src={slide1} alt="Alpaca Elite Club" />
                            </div>
                        </div>
                    </div>
            </section>
        </div>
    );
}


export default SliderStyle4;
