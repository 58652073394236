import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {BrowserRouter} from 'react-router-dom'
import ScrollToTop from './ScrollToTop';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-WM93LVH'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <BrowserRouter >
    <ScrollToTop />
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);
