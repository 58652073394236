import React , { useState } from 'react';
import { Link } from 'react-router-dom'
import { Accordion } from 'react-bootstrap-accordion'

function FAQ({data}) {
    return (
            <section name="faq" id="faq" className="tf-section wrap-accordion faq inner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="tf-title-heading ct style-1 fs-10 mg-bt-10">
                                Frequently Asked Questions (FAQ)
                            </h2>
                        </div>
                        <div className="col-md-12">
                            <div className="flat-accordion2">
                                {
                                    data.map((item,index) => (
                                        <Accordion key={index} title={item.question} >
                                            <p>{item.answer}</p>
                                        </Accordion>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default FAQ;
